function requireAll(r) { r.keys().forEach(r); }

requireAll( require.context( './ico/', true ) );
requireAll( require.context( './svg/', true, /\.svg$/) );
//requireAll( require.context( './gif/', true, /\.gif$/) );
//requireAll( require.context( './fonts/', true ) );

import $ from 'jquery';

import Swiper from 'swiper';
import Cookies from 'js-cookie';

window.jQuery = $;
window.$ = $;

var gMapsLoaded = false;
var googleMapApiKey = 'AIzaSyAX30w4IcAkOv4lhxLOosCRcF4oEcGv-M8';

window.gMapsCallback = function(){

    gMapsLoaded = true;

    $(window).trigger('gMapsLoaded');

}

var dataLayer = window.dataLayer = window.dataLayer || null;

$(function () {

	var PROJECT = {

		common: {

			getViewport: function() {

				var viewPortWidth;
				var viewPortHeight;

				if (typeof window.innerWidth !== 'undefined') {

					viewPortWidth = window.innerWidth;
					viewPortHeight = window.innerHeight;

				} else if ( typeof document.documentElement !== 'undefined' &&
							typeof document.documentElement.clientWidth !== 'undefined' &&
							document.documentElement.clientWidth !== 0 ) {

					viewPortWidth = document.documentElement.clientWidth;
					viewPortHeight = document.documentElement.clientHeight;

				} else {

					viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
					viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
				}

				return { width : viewPortWidth , height : viewPortHeight };

			},

			init: function () {

				PROJECT.menu.init();

				PROJECT.testimonals.init();

				PROJECT.video.init();

				$( window ).on( 'resize orientationchange', PROJECT.testimonals.init );

				PROJECT.common.links();
				PROJECT.common.calcCurve();
				PROJECT.common.accordion();


				PROJECT.form.fileupload();

				PROJECT.map.init();

				PROJECT.cookies.init();

			},

			calcCurve: function() {

				var $calcCurve = $( '.calcCurve' );

				if ( $calcCurve.length ) {

					var calcCurvePosition = $calcCurve.offset();

					var bodyHeight = $( document ).height();

					$( '.calcCurve' ).height( ( bodyHeight - calcCurvePosition.top ) );

				}

			},

			links: function() {

				$( document ).on( 'click', 'a[href="\\#back"]', function(e) {

					if ( document.referrer.indexOf( window.location.host ) !== -1  ) {

						e.preventDefault();

						history.back();

					} else {

						window.location.href = '/';
					}

				});




				// Select all links with hashes // Remove links that don't actually link to anything
				$( document ).on( 'click', 'a[href*="\\#"]:not([href="#"]):not([href="\\#back"])', function(e) {

					var hash = this.hash;

					if ( hash == '' ) {

						e.preventDefault();

						return false;

					} else if ( location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname ) {

						// Figure out element to scroll to
						var $target = hash.length ? $(hash) : $('[name=' + hash.slice(1) + ']');

						// Does a scroll target exist?
						if ( $target.length ) {

							// Only prevent default if animation is actually gonna happen
							e.preventDefault();

							var scrollTop = $target.offset().top - $('#main-nav').outerHeight() + 24;

							$('html, body').animate({

								scrollTop: scrollTop,

							}, 1000, function() {

								// Callback after animation

								// Only if the scrollTop is the same as the target offset top
								// Must change focus!

								if ( scrollTop == $target.offset().top ) {

									$target.focus();

									if ( $target.is(":focus") ) { // Checking if the target was focused

										return false;

									} else {

										$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable

										$target.focus(); // Set focus again

									};

								}

								// Progressive Enhancement
								if ( history.pushState ) {

								 	history.pushState(null, null, hash );

								}

							});

						}

					}

				});

			},

			accordion: function() {

				$( document ).on( 'click', '.accordionToggle', function(e) {

					$( this ).toggleClass( 'is-open' );

					var $panel = $( this ).find( '.accordionSlide' ).get( 0 );

					if ( $panel.style.maxHeight ) {

						$panel.style.maxHeight = null;

					} else {
						$panel.style.maxHeight = $panel.scrollHeight + "px";

					}

				});
			}

		},

		cookies: {



			interval: '',

			timing: 100,			// checking every 0.1 seconds
			timeOut: 3000,			// max running 3 seconds
			timeRunning: 0,			// counter


			init: function() {

				PROJECT.cookies.timeRunning = 0;

				PROJECT.cookies.interval = setInterval( PROJECT.cookies.checkForDataLayer, PROJECT.cookies.timing );

				PROJECT.cookies.check();

				$( document ).on( 'click', '.js-accept-cookies', function( e ) {

					e.preventDefault();

					PROJECT.cookies.set();

				});

				$( document ).on( 'click', '.js-delete-cookies', function( e ) {

					e.preventDefault();

					PROJECT.cookies.delete();

				});

			},

			check: function() {

				var cookiesAccepted = Cookies.get('acceptscookies');

				if ( cookiesAccepted == 'yes' ) {

					$( '.js-cookie-bar' ).fadeOut();
				}

			},

			set: function() {

				Cookies.set('acceptscookies', 'yes', { expires: 365 });

				$( '.js-cookie-bar' ).fadeOut();

			},

			delete: function() {

				Cookies.rmove('acceptscookies');

				$( '.js-cookie-bar' ).fadeIn();
			},



			checkForDataLayer: function() {

				PROJECT.cookies.timeRunning = PROJECT.cookies.timeRunning + PROJECT.cookies.timing;

				if ( dataLayer != null  ) {

					PROJECT.cookies.pushToDataLayer();

				}

				if ( dataLayer != null || PROJECT.cookies.timeRunning >= PROJECT.cookies.timeOut ) {

					clearInterval( PROJECT.cookies.interval );

				}

			},

			pushToDataLayer: function() {

				var cookiesAccepted = Cookies.get('acceptscookies');

				if ( cookiesAccepted == 'yes' ) {

					dataLayer.push({'event':'cookiesaccepted'});

					console.log('accepted');

				}

			}


		},

		video: {

			init: function() {


				$( document ).on( 'click', '.videoClick', PROJECT.video.openPopup );

			//	$( document ).on( 'click scroll', PROJECT.video.closePopupDoc );
				$( document ).on( 'click', '.videoPopupOpen', PROJECT.video.closePopup );

			},

			openPopup: function( e ) {

				e.preventDefault();

				var videoTitle = $( this ).data( 'video-title' );
				var videoText = $( this ).data( 'video-text' );
				var videoUrl = $( this ).data( 'video-url' );

				var popupTemplate = $( '.videoPopup' ).html();

				var $popupObject = $('<div/>').html( popupTemplate ).contents();

				$popupObject.find( '.popupTitle' ).text( videoTitle );
				$popupObject.find( '.popupText' ).text( videoText );
				$popupObject.find( '.popupVideo' ).attr( 'src', videoUrl );

				$popupObject.hide();

				$( 'body' ).append( $popupObject );

				$popupObject.fadeIn( 300, function() {

					$( 'html' ).addClass( 'state-view-video-popup--is-open' );

				});

			},

			closePopupDoc: function( e ) {

				if ( $( 'html' ).hasClass( 'state-view-video-popup--is-open' ) ) {

					var popupWrapper = $( '.videoPopupWrapper' );

					if	(
						!popupWrapper.is(e.target)
						&&
						popupWrapper.has(e.target).length === 0
					) {

						PROJECT.video.closePopup();

					}

				}

			},

			closePopup: function( e ) {

				$( '.videoPopupOpen' ).fadeOut( 300, function() {

					$( this ).remove();

					$( 'html' ).removeClass( 'state-view-video-popup--is-open' );

				});

			}

		},

		menu: {

			countryToggle: 'countryToggle',
			countryDropdown: 'countryDropdownList',

			heroCountryToggle: 'heroCountryToggle',
			heroCountryDropdown: 'heroCountryDropdownList',

			navToggle: 'navToggle',
			navNav: 'navNav',

			init: function() {

				$( document ).on( 'click', '.' + PROJECT.menu.navToggle, PROJECT.menu.openNav );

				$( document ).on( 'click', '.' + PROJECT.menu.countryToggle, PROJECT.menu.openCountry );

				$( document ).on( 'click', '.' + PROJECT.menu.heroCountryToggle, PROJECT.menu.openHeroCountry );

				$( document ).on( 'click scroll', PROJECT.menu.closeCountry );
				$( document ).on( 'click scroll', PROJECT.menu.closeNav );
				$( document ).on( 'click scroll', PROJECT.menu.closeHeroCountry );

			},

			openCountry: function( e ) {

				e.preventDefault();

				$( '.' + PROJECT.menu.countryDropdown ).slideDown( 200, function() {

					$( 'html' ).addClass( 'state-view-dropdown--is-open' );

				} );

			},

			closeCountry: function( e ) {

				if ( $( 'html' ).hasClass( 'state-view-dropdown--is-open' ) ) {

					var countryDropdownList = $( '.' + PROJECT.menu.countryDropdown );

					if	(
						!countryDropdownList.is(e.target)
						&&
						countryDropdownList.has(e.target).length === 0
					) {

						$( '.' + PROJECT.menu.countryDropdown ).slideUp( 200 );

					}

					$( 'html' ).removeClass( 'state-view-dropdown--is-open' );

				}

			},

			openHeroCountry: function( e ) {

				e.preventDefault();

				$( '.' + PROJECT.menu.heroCountryDropdown ).slideDown( 200, function() {

					$( 'html' ).addClass( 'state-view-dropdown-hero--is-open' );

				} );

			},

			closeHeroCountry: function( e ) {

				if ( $( 'html' ).hasClass( 'state-view-dropdown-hero--is-open' ) ) {

					var countryDropdownList = $( '.' + PROJECT.menu.heroCountryDropdown );

					if	(
						!countryDropdownList.is(e.target)
						&&
						countryDropdownList.has(e.target).length === 0
					) {

						$( '.' + PROJECT.menu.heroCountryDropdown ).slideUp( 200 );

					}

					$( 'html' ).removeClass( 'state-view-dropdown-hero--is-open' );

				}

			},

			openNav: function( e ) {

				e.preventDefault();

				$( 'html' ).addClass( 'state-view-nav--is-active' );

				$( '.' + PROJECT.menu.navNav ).slideDown( 300, function() {

					$( 'html' ).addClass( 'state-view-nav--is-open' );

				} );

			},

			closeNav: function( e ) {

				if ( $( 'html' ).hasClass( 'state-view-nav--is-open' ) ) {

					var navNav = $( '.' + PROJECT.menu.navNav );

					if	(
						!navNav.is(e.target)
						&&
						navNav.has(e.target).length === 0
					) {

						navNav.slideUp( 200 );

					}

					$( 'html' ).removeClass( 'state-view-nav--is-open' );
					$( 'html' ).removeClass( 'state-view-nav--is-active' );

				}

			},

		},

		testimonals: {

			testimonalsSwiper: '',

			init: function() {

				var viewport = PROJECT.common.getViewport();

				if ( typeof PROJECT.testimonals.testimonalsSwiper.destroy === "function" ) {

					PROJECT.testimonals.testimonalsSwiper.destroy( true, true );

				}

				var swiperConfig = {
					centeredSlides: true,
					slideClass:		'testimonialsItem',
					wrapperClass:	'testimonialsWrapper',

					loop:			true,

					pagination:		{

						el:					'.testimonialsPager',
						bulletClass:		'testimonials__page',
						bulletActiveClass:	'testimonials__page--is-active',

					},

					navigation: {

						nextEl:		'.testimonialsNext',
						prevEl:		'.testimonialsPrev',

					},

					keyboard: {

						enabled: true,
						onlyInViewport: false,

					},

					slidesPerView:	1

				};

				if ( viewport.width > 768 ) {

					swiperConfig.slidesPerView = 3;

				}

				PROJECT.testimonals.testimonalsSwiper = new Swiper ('.testimonialsCarousel', swiperConfig );

			},

		},

		form: {

			fileupload: function() {

				$( '.inputFile' ).each( function() {

					var $input	 = $( this ),
						$label	 = $input.next( 'label' ),
						labelVal = $label.html();

					$input.on( 'change', function( e ) {

						var fileName = '';

						if ( this.files && this.files.length > 1 ) {

							fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );

						} else if ( e.target.value ) {

							fileName = e.target.value.split( '\\' ).pop();

							if ( fileName ) {

								$label.find( 'span' ).html( fileName );

							} else {

								$label.html( labelVal );

							}

						}
					});

					// Firefox bug fix
					$input
						.on( 'focus', function(){ $input.addClass( 'has-focus' ); })
						.on( 'blur', function(){ $input.removeClass( 'has-focus' ); });

				});

			}

		},

		map: {

			init: function() {

				if ( $('.gMap').length ) {

					$( window ).bind( 'gMapsLoaded', PROJECT.map.geocode );

					PROJECT.map.load();

				}

			},

			load: function() {

				if(gMapsLoaded) return window.gMapsCallback();

				var script_tag = document.createElement('script');

				script_tag.setAttribute("type","text/javascript");

				script_tag.setAttribute("src","http://maps.google.com/maps/api/js?key="+googleMapApiKey+"&callback=gMapsCallback");

				(document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);

			},

			callback: function() {

				gMapsLoaded = true;

				$(window).trigger('gMapsLoaded');

			},

			geocode: function() {

				$( '.gMap' ).each( function() {

					var mapData = $( this ).data();
					var mapElement = $( this );

					if( mapData.location ) {

						var geocoder =  new google.maps.Geocoder();

						geocoder.geocode( { 'address': mapData.location }, function( results, status ) {

							console.log( results );

							if ( status == 'OK' ) {

								mapData.result = results;
								mapData.element = mapElement;

								PROJECT.map.loadMap( mapData );

							} else {

								console.log( 'Geo Code error' );
							}

						});

					}

				});

			},

			loadMap: function( mapData ) {

				var location = mapData.location;
				var radius = mapData.radius;
				var lat = mapData.result[0].geometry.location.lat();
				var lng = mapData.result[0].geometry.location.lng();
				var center = new google.maps.LatLng( lat, lng );

				var mapElement = mapData.element;
				var mapOptions = {

					zoom: 7,

					center: center,

					draggable: false,

					scrollwheel: false,

					disableDefaultUI: true,

					// styles: [
					// 	{
					// 		"featureType": "all",
					// 		"elementType": "all",
					// 		"stylers": [
					// 			{
					// 				"visibility": "on"
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "all",
					// 		"elementType": "labels.text.fill",
					// 		"stylers": [
					// 			{
					// 				"saturation": 36
					// 			},
					// 			{
					// 				"color": "#333333"
					// 			},
					// 			{
					// 				"lightness": 40
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "all",
					// 		"elementType": "labels.text.stroke",
					// 		"stylers": [
					// 			{
					// 				"visibility": "on"
					// 			},
					// 			{
					// 				"color": "#ffffff"
					// 			},
					// 			{
					// 				"lightness": 16
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "all",
					// 		"elementType": "labels.icon",
					// 		"stylers": [
					// 			{
					// 				"visibility": "off"
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "administrative",
					// 		"elementType": "geometry",
					// 		"stylers": [
					// 			{
					// 				"visibility": "simplified"
					// 			},
					// 			{
					// 				"saturation": "0"
					// 			},
					// 			{
					// 				"lightness": "0"
					// 			},
					// 			{
					// 				"gamma": "0"
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "administrative",
					// 		"elementType": "geometry.fill",
					// 		"stylers": [
					// 			{
					// 				"color": "#fefefe"
					// 			},
					// 			{
					// 				"lightness": 20
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "administrative",
					// 		"elementType": "geometry.stroke",
					// 		"stylers": [
					// 			{
					// 				"color": "#fefefe"
					// 			},
					// 			{
					// 				"lightness": 17
					// 			},
					// 			{
					// 				"weight": 1.2
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "administrative",
					// 		"elementType": "labels",
					// 		"stylers": [
					// 			{
					// 				"visibility": "simplified"
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "landscape",
					// 		"elementType": "all",
					// 		"stylers": [
					// 			{
					// 				"visibility": "simplified"
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "landscape",
					// 		"elementType": "geometry",
					// 		"stylers": [
					// 			{
					// 				"color": "#fefefe"
					// 			},
					// 			{
					// 				"lightness": 20
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "landscape",
					// 		"elementType": "labels",
					// 		"stylers": [
					// 			{
					// 				"visibility": "simplified"
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "poi",
					// 		"elementType": "all",
					// 		"stylers": [
					// 			{
					// 				"visibility": "off"
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "poi",
					// 		"elementType": "geometry",
					// 		"stylers": [
					// 			{
					// 				"color": "#f5f5f5"
					// 			},
					// 			{
					// 				"lightness": 21
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "poi.park",
					// 		"elementType": "geometry",
					// 		"stylers": [
					// 			{
					// 				"color": "#dedede"
					// 			},
					// 			{
					// 				"lightness": 21
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "road",
					// 		"elementType": "all",
					// 		"stylers": [
					// 			{
					// 				"visibility": "off"
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "road.highway",
					// 		"elementType": "geometry.fill",
					// 		"stylers": [
					// 			{
					// 				"color": "#ffffff"
					// 			},
					// 			{
					// 				"lightness": 17
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "road.highway",
					// 		"elementType": "geometry.stroke",
					// 		"stylers": [
					// 			{
					// 				"color": "#ffffff"
					// 			},
					// 			{
					// 				"lightness": 29
					// 			},
					// 			{
					// 				"weight": 0.2
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "road.arterial",
					// 		"elementType": "geometry",
					// 		"stylers": [
					// 			{
					// 				"color": "#ffffff"
					// 			},
					// 			{
					// 				"lightness": 18
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "road.local",
					// 		"elementType": "geometry",
					// 		"stylers": [
					// 			{
					// 				"color": "#ffffff"
					// 			},
					// 			{
					// 				"lightness": 16
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "transit",
					// 		"elementType": "all",
					// 		"stylers": [
					// 			{
					// 				"visibility": "off"
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "transit",
					// 		"elementType": "geometry",
					// 		"stylers": [
					// 			{
					// 				"color": "#f2f2f2"
					// 			},
					// 			{
					// 				"lightness": 19
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "water",
					// 		"elementType": "geometry",
					// 		"stylers": [
					// 			{
					// 				"color": "#cccccc"
					// 			},
					// 			{
					// 				"lightness": 17
					// 			}
					// 		]
					// 	},
					// 	{
					// 		"featureType": "water",
					// 		"elementType": "labels",
					// 		"stylers": [
					// 			{
					// 				"visibility": "off"
					// 			}
					// 		]
					// 	}
					// ]

				};

				var map = new google.maps.Map( mapElement[0], mapOptions );

				var cityCircle = new google.maps.Circle({
					strokeColor: '#e42313',
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: '#e42313',
					fillOpacity: 0.35,
					map: map,
					center: center,
					radius: radius * 2500
				});



			}

		}

	};

	var UTIL = {

		exec: function (controller, action) {

			var ns = PROJECT;
			action = (action === undefined) ? 'init' : action;

			if (controller !== '' && ns[controller] && typeof ns[controller][action] === 'function') {

				ns[controller][action]();

			}


		},

		init: function () {

			var body = document.body,
				controller = body.getAttribute('data-controller'),
				action = body.getAttribute('data-action');

			UTIL.exec('common');
			UTIL.exec(controller);
			UTIL.exec(controller, action);

		}

	};

	//$.PROJECT = PROJECT; // Make global available

	$(window).ready(UTIL.init);

});
